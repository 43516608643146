<template>
  <div class="container">
    <div class="head-bg">
      <van-swipe :autoplay="5000" lazy-render :show-indicators="false" >
          <van-swipe-item v-for="item in bannerList" :key="item.id">
            <div
              class="banner"
            > 
              <van-image
                :src="require('@/assets/home/head-bg.png')"
                fit="container"
                height="40vw"
                width="93.6vw"
              ></van-image>
            </div>
          </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 基础数据 -->
    <div class="box" style="margin-top: 0;">
      <div class="box-title" style="margin-top: 0;">
        基础数据
      </div>
      <div class="data">
        <div class="data-content" @click="$router.push({path:'/project/index'})">
          <img src="@/assets/home/message1.png" alt="">
          <div class="text">
            <p><span>{{baseData.examNum}}</span>次</p>
            <p>测评场数</p>
          </div>
        </div>
        <div class="data-content bg2" @click="$router.push({path:'/record'})">
          <img src="@/assets/home/message2.png" alt="">
          <div class="text">
            <p><span>{{baseData.profileNum}}</span>个</p>
            <p>学生档案</p>
          </div>
        </div>
        <div class="data-content bg3" @click="$router.push({path:'/project/index',query:{type:2}})">
          <img src="@/assets/home/message3.png" alt="">
          <div class="text">
            <p><span>{{baseData.testingNum}}</span>份</p>
            <p>测评报告</p>
          </div>
        </div>
        <div class="data-content bg4">
          <img src="@/assets/home/message4.png" alt="">
          <div class="text">
            <p><span>{{baseData.parseNum}}</span>次</p>
            <p>干预人次</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 常用功能 -->
    <div class="box">
      <div class="box-title">常用功能</div>
      <div class="options">
        <div class="option" v-for="item in options" :key="item.id" @click="$router.push({path:item.url,query:{type:item.type}})">
          <img :src="require(`@/assets/home/option${item.id}.png`)" alt="" style="width: 8vw;height: 8vw;margin-bottom: 1.8667vw;">
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <!-- 待办事项 -->
    <div class="box">
      <div class="box-title" style="margin-bottom: 5.3333vw;">待办事项</div>
      <div class="todoList">
        <div class="todoList-content">
          <div class="todoList-title">
            <img src="@/assets/home/title-bg1.png" alt="" style="width: 25.6vw;height: 6.9333vw;">
            <p>预警名单</p>
          </div>
          <div class="todoList-message">
            <div class="text" @click="$router.push({path:'/warn/index'})">
              <p>{{todos_v2.warning_static_all}}<span>人</span></p>
              <span>静态测评预警</span>
            </div>
            <div class="text" @click="$router.push({path:'/warn/index',query:{type:2}})">
              <p>{{todos_v2.warning_dynamic_all}}<span>人</span></p>
              <span >动态观察预警</span>
            </div>
          </div>
        </div>
        <div class="todoList-content">
          <div class="todoList-title" style="width: 33.6vw;">
            <img src="@/assets/home/title-bg2.png" alt="" style="width: 33.6vw;height: 6.9333vw;">
            <p style="color: #FFBD40 ;">危机干预名单</p>
          </div>
          <div class="todoList-message">
            <div class="text" @click="$router.push({path:'/my/crisis',query:{active:1}})">
              <p>{{todos_v2.risk_1_all}}<span>人</span></p>
              <span>一级危机名单</span>
            </div>
            <div class="text" @click="$router.push({path:'/my/crisis',query:{active:2}})">
              <p>{{todos_v2.risk_2_all}}<span>人</span></p>
              <span>二级危机名单</span>
            </div>
            <div class="text" @click="$router.push({path:'/my/crisis',query:{active:3}})">
              <p>{{todos_v2.risk_3_all}}<span>人</span></p>
              <span>三级危机名单</span>
            </div>
          </div>
        </div>
        <div class="todoList-content">
          <div class="todoList-title" style="width: 30.9333vw;">
            <img src="@/assets/home/title-bg3.png" alt="" style="width: 33.6vw;height: 6.9333vw;">
            <p style="color: #4FC6AD  ;">动态观察小组</p>
          </div>
          <div class="todoList-message">
            <div class="text">
               <p>{{todos_v2.helperNum_1}}<span>人</span></p>
               <span>联动观察成员</span>
             </div>
             <div class="text">
               <p>{{todos_v2.helperNum_0}}<span>人</span></p>
               <span>待审核成员</span>
             </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :activeIndex="0"></tabbar>
  </div>
</template>

<script>
import { bannerList } from "@/api/project";
import {home} from '@/api/user.js'
import Tabbar from "@/components/Tabbar";
export default {
  name: "Home",
  components: {
    Tabbar,
  },
  data() {
    return {
      bannerList:[
        {id:1,title:'测试',imageUrl:'https://img01.yzcdn.cn/vant/apple-1.jpg'}
      ],
      options:[
        {id:1,title:'发布测评',url:'/project/add'},
        {id:2,title:'联动观察记录',url:''},
        {id:3,title:'静态测评预警',url:'/warn/index'},
        {id:4,title:'动态观察预警',url:'/warn/index',type:2},
        {id:5,title:'危机干预名单',url:'/my/crisis'},
        {id:6,title:'危机解除名单',url:'/my/crisisRelieve'},
        {id:7,title:'报告管理',url:'/project/index',type:2},
        {id:8,title:'学生档案',url:'/record'},
      ],
      mode: 1,
      stepIndex: 0,
      bannerQuerry:{
        page:1,
        pageSize:5
      },
      baseData:{},
      exams:{},
      todos_v2:{},
      active:0
    };
  },
  watch:{
    $route:{
      immediate:true,
      async handler(){
        if(this.$route.query?.code&&!this.$store.state.token){
          console.log('code',this.$route.query?.code);
          console.log('wxtoken',sessionStorage.getItem('wx_token'));
          console.log('存储的token',this.$store.state.token);
          console.log('backUrl',this.$route.query.backUrl);
          await this.$store.dispatch('weChatLogin',{code:this.$route.query.code,backUrl:this.$route.query.backUrl})
        }
      }
    }
  },
  created() {
    this.getHome()
  },
  methods: {
    async getBanner() {
      const res=await bannerList({...this.bannerQuerry})
      this.bannerList=res.data.list
    },
    async getHome(){
      const res = await home()
      this.baseData = res.data.baseData
      this.exams = res.data.exam
      this.todos_v2=res.data.todos_v2
    },
    gotoPage(url) {
      this.$router.push(url);
    },
    gotoBanner(url){
      const ref=RegExp('/project/detail/')
      if(url.match(ref)){
        let arr=url.split('/project/detail/')
        this.$router.push(`/project/detail/${arr[1]}`)
      }else{
        location.href=url
      }

    },
  },
};
</script>

<style scoped lang="less">
.container{
  min-height: 100%;
  padding: 0 0 13.3333vw 0;
  .head-bg{
    padding: 6.6667vw 3.2vw 6.6667vw 3.2vw;
    background: linear-gradient(180deg, #C4F3DF 0%, #FFF 100%);
  }
  .box{
    padding: 0 3.2vw;
    .box-title{
      margin: 8vw 0 4vw 0;
      padding-left: 3.4667vw;
      font-size: 4.8vw;
      font-weight: 550;
    }
    .data{
      padding: 0 4vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .data-content{
        display: flex;
        flex-shrink: 0;
        // justify-content: space-between;
        align-items: center;
        padding: 0 6.1333vw;
        margin-top:2.6667vw;
        width: 47%;
        height: 16vw;
        // border: .2667vw solid rgba(187, 187, 187, 1);
        font-size: 3.7333vw;
        background: url(../../assets/home/message-bg1.png) center/100% 100% no-repeat;
        &:nth-of-type(2n){
          margin-left: 6%;
        }
        .text{
          font-size: 2.9333vw;
          color: #7d7d7d;
          span{
            font-size: 4.2667vw;
            color: #E2422C;
            font-weight: 550;
            /* margin-left: 2.6667vw;
            flex: 1;
            font-size: 4.8vw; */
          }
        }
        img{
          width: 8vw;
          height: 8vw;
          margin-right: 4.8vw;
        }
      }
      .data-content.bg2{
        background: url(../../assets/home/message-bg2.png) center/100% 100% no-repeat;
      }
      .data-content.bg3{
        background: url(../../assets/home/message-bg3.png) center/100% 100% no-repeat;
      }
      .data-content.bg4{
        background: url(../../assets/home/message-bg4.png) center/100% 100% no-repeat;
      }
    }
    .options{
      display: flex;
      // padding-bottom: 4vw;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      // overflow: scroll;
      .option{
        // margin-right: 4.8vw;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        font-size: 2.9333vw;
        &:nth-of-type(n+5){
          margin-top: 5.3333vw;
        }
      }
    }
    .todoList-content{
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      margin-bottom: 5.3333vw;
      height: 30.1333vw;
      font-size: 3.4667vw;
      box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
      border-radius: 2.6667vw;
      .todoList-title{
        position: relative;
        width: 25.6vw;
        height: 6.9333vw;
        p{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%,-50%,0);
          color: #E2422C ;
          font-weight: 550;
          white-space: nowrap;
        }
      }

      .todoList-message{
        display: flex;
        // justify-content: center;
        align-items: center;
        height: 100%;
        .text{
          flex: 1;
          text-align: center;
          border-right: .2667vw solid #f1f1f1;
          color: #E23F2A;
          &:first-child{
            color: #F0A248;
          }
          &:last-child{
            border: none;
          }
          span{
            font-size: 2.9333vw;
            font-weight: 400;
            &:last-child{
              color: #7A7B7D;

            }
          }
          p{
            font-size: 6.4vw;
            font-weight: 550;
            margin-bottom: 1.3333vw;
          }
        }
      }
    }
  }
}
</style>
